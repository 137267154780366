class Callbacks {
  static onKeyDownHandler(dialogs, ctrlKeyMap, altKeyMap, baseKeyMap) {
    return function onKeyDown(e) {
      const { altKey, ctrlKey } = e
      if (!!e?.repeat || !!_.some(dialogs, 'isOpen')) return
      !!ctrlKey && ctrlKeyMap(e)
      !!altKey && altKeyMap(e)
      !altKey && !ctrlKey && baseKeyMap(e)
    }
  }
  static ctrlKeyMapHandler(record, extra, extraFunctions) {
    return async function ctrlKeyMap(event) {
      const { key } = event
      const { handleCancel, refuse, onSubmitHandler, handleWaitingOrange, handleOpenRedispatch, handleOpenClaimRedispatch, openNotification } = extraFunctions
      const { isManagingBillingRecord, isManagingRecord, canOrangeWait } = extra
      switch (key) {
        case 'F5':
          event.preventDefault()
          handleOpenClaimRedispatch()
          break;
        case 'F6':
          if (!isManagingRecord) return
          event.preventDefault()
          handleOpenRedispatch()
          break;
        case 'F8':
          event.preventDefault()
          handleCancel()
          break;
        case 'F9':
          if (!canOrangeWait) return
          event.preventDefault()
          await handleWaitingOrange()
          break;
        case 'F12':
          if (!isManagingBillingRecord) return openNotification(I18n.t('toast.billing.error.wrong_mode'), { variant: 'error' })
          event.preventDefault()
          refuse()
          break;
        case '²':
          event.preventDefault()
          onSubmitHandler({ disableRedirect: !!isManagingRecord, confirmed: false })
          break;
        case 'Enter':
        case 'ArrowRight':
          event.preventDefault()
          onSubmitHandler({ disableRedirect: key !== 'Enter' || !!isManagingRecord })
          break;
        default:
          return false
      }
    }
  }
  static altKeyMapHandler(record, extra, extraFunctions) {
    return async function altKeyMap(event) {
      const { key } = event
      const { handleOpenSendMessageDriver, handleChangeIsB2B } = extraFunctions
      const { isManagingRecord, gsm, operator } = extra
      const driver_id = _.get(record, 'assigned_transport.driver.user_id')

      switch (key) {
        case 'F2':
          if (!operator) return
          event.preventDefault()
          window.open(`/order?operator_id=${_.get(operator, 'user_id')}`, '_blank')
          break;
        case 'F3':
          if (!gsm) return
          event.preventDefault()
          window.open(`/order?client_phone=${_.replace(gsm, '+', '')}`, '_blank')
          break;
        case 'F5':
          event.preventDefault()
          window.open('/order', '_blank').focus()
          break;
        case 'F6':
          event.preventDefault()
          window.open('/dispatch', '_blank').focus()
          break;
        case 'F9':
          if (!driver_id) return
          event.preventDefault()
          window.open(`/order?driver_id=${driver_id}`, '_blank')
          break;
        case 'F10':
          event.preventDefault()
          if (!driver_id) return
          window.open(`/driver?user_id=${driver_id}`, '_blank')
          break;
        case 'F11':
          if (!isManagingRecord || !driver_id) return
          event.preventDefault()
          handleOpenSendMessageDriver()
          break;
        case 'F12':
          window.open('/incident', '_blank').focus()
          break;
        case 'Enter':
          handleChangeIsB2B()
          event.preventDefault()
          break;
        default:
      }
    }
  }
  static baseKeyMapHandler(record, extra, extraFunctions) {
    return async function baseKeyMap(event) {
      const { key } = event
      const { setFormState, navigate, handleOpenSendCommentDialog, handleOpenManualDispatch, handleCall, approve, handleClickMap, handleOpenShortcut } = extraFunctions
      const {
        isManagingRecord,
        isManagingBillingRecord,
        isMapOpen,
        isReadOnly,
        driver_number,
        customer_number,
        focusedField,
        companycontract,
        companyservice
      } = extra
      switch (key) {
        case 'Tab':
          if (_.get(focusedField, 'field') === 'user.companycontract' && !companycontract)
            event.preventDefault()
          else if (_.get(focusedField, 'field') === 'user.companyservice' && !companyservice)
            event.preventDefault()
          break;
        case 'F1':
          event.preventDefault()
          handleOpenShortcut()
          break;
        case 'F2':
          if (!isManagingRecord) return
          event.preventDefault()
          handleOpenSendCommentDialog()
          break;
        case 'F5':
          event.preventDefault()
          handleClickMap(isMapOpen)
          break;
        case 'F6':
          if (!isManagingRecord) return
          event.preventDefault()
          handleOpenManualDispatch()
          break;
        case 'F7':
          event.preventDefault()
          await handleCall(driver_number)
          break;
        case 'F8':
          event.preventDefault()
          setFormState({ isReadOnly: false })
          break;
        case 'F9':
          event.preventDefault()
          await handleCall(customer_number)
          break;
        case 'F12':
          if (!isManagingBillingRecord) openNotification(I18n.t('toast.billing.error.wrong_mode'), { variant: 'error' })
          else approve()
          break;
        case 'Enter':
          if (!isManagingRecord || !isReadOnly) return
          navigate('/order/create')
          break;
        case 'Escape':
          if (!isManagingRecord || !!isReadOnly) return
          setFormState({ isReadOnly: true })
          break;
        default:
      }
    }
  }
}

export default Callbacks
